<template>
	<b-card :title="`${$t('Program Name')}：${infoData.name || '/'}`" v-loading="loading">
		<b-row v-if="infoData.w">
			<b-col cols="12"><h3 class="mb-2">{{$t('Base Info')}}</h3></b-col>
			<b-col class="pb-2" cols="6" md="4" lg="3">
				<h6>UUID</h6>
				<p>{{uuid}}</p>
			</b-col>
			<b-col class="pb-2" cols="6" md="4" lg="3">
				<h6>{{$t('Size')}}</h6>
				<p v-if="sizeSetStatus==0">{{infoData.w || '/'}}px * {{infoData.h || '/'}}px <b-button class="ml-2" variant="outline-primary" size="sm" @click="sizeSetStatus=1">{{$t('Set')}}</b-button></p>
				<p v-else>
					<b-form-input
						class="d-inline-block"
						v-model="infoData.w"
						trim
						size="sm"
						:placeholder="$t('Please Enter...')"
						style="width:60px"
					/>
					px
					*
					<b-form-input
						class="d-inline-block"
						v-model="infoData.h"
						trim
						size="sm"
						:placeholder="$t('Please Enter...')"
						style="width:60px"
					/>
					px
					<b-button class="ml-2" variant="primary" size="sm" @click="saveSize">{{$t('Save')}}</b-button>
					<b-button class="ml-1" variant="outline-primary" size="sm" @click="sizeSetStatus=0">{{$t('Cancel')}}</b-button>
				</p>
			</b-col>
			<b-col class="pb-2" cols="6" md="4" lg="3">
				<h6>{{$t('Create Time')}}</h6>
				<p>{{infoData.created_at || '/'}}</p>
			</b-col>
			<b-col class="pb-2" cols="6" md="4" lg="3">
				<h6>{{$t('Update Time')}}</h6>
				<p>{{infoData.updated_at || '/'}}</p>
			</b-col>
			<b-col class="pb-2" cols="6" md="4" lg="3">
				<h6>{{$t('Status')}}</h6>
				<p>
					<b-badge :variant="statusObj[infoData.status].color">{{statusObj[infoData.status].label}}</b-badge>
				</p>
			</b-col>
			<b-col class="pb-2" cols="6" md="4" lg="3">
				<h6>{{$t('Auditor')}}</h6>
				<p>/</p>
			</b-col>
			<b-col class="pb-2" cols="6" md="4" lg="3">
				<h6>{{$t('Audit Time')}}</h6>
				<p/>/</p>
			</b-col>
		</b-row>
		<Function>
			<ul class="colors-list list-unstyled mb-0">
				<li>
					<feather-icon
						size="15"
						icon="ChevronsRightIcon"
					/>
					<span>{{$t('Function Declaration')}}1</span>
				</li>
				<li>
					<feather-icon
						size="15"
						icon="ChevronsRightIcon"
					/>
					<span>{{$t('Function Declaration')}}2</span>
				</li>
			</ul>
		</Function>
	</b-card>
</template>

<script>
import { BCard, BCardText, BRow, BCol,BButton, BFormInput, BBadge } from 'bootstrap-vue'
import { getProgramInfo } from "@/libs/apis/serverApi";

export default {
	components: {
		BCard,
		BCardText,
		BRow, BCol,
		BButton,
		BFormInput,
		BBadge
	},
	data () {
		return {
			uuid: this.$route.query.uuid,
			loading: false,
			sizeSetStatus: 0,
			width: 1920,
			height:1080,
			statusOptions: [
				{ label: '全部', value: '-1' },
				{ label: '编辑中', value: '0', color: 'primary' },
				{ label: '已完成', value: '1', color: 'success' },
			],
			infoData:{}
		}
	},
	computed: {
		statusObj () {
			let temp = {}
			this.statusOptions.forEach(item=>{
				temp[item.value] = item
			})
			return temp
		}
	},
	created () {
		if(!this.uuid){
			this.$router.back()
			return
		}
		this.loading = true
		getProgramInfo({uuid: this.uuid}).then(res=>{
			this.loading = false
			this.infoData = res.row
		},err=>{
			this.loading = false
			this.$toast(err.message)
		})
	},
	methods: {
		saveSize () {
			this.sizeSetStatus = 0
		},
	}
}
</script>

<style>

</style>
